<template>
    <div>
        <!--@edit-actived="cellClickEvent" 编辑修改的时候不需要查询 -->
        <vxe-grid
                :id="id"
                ref="baseGird"
                border
                resizable
                show-overflow
                keep-source
                align="left"
                size="mini"
                :height="height"
                highlight-current-row
                show-footer
                :print-config="{}"
                :import-config="{
                    remote: true,
                    importMethod: this.importMethod,
                    modes: ['insert'],
                    type: ['xlsx'],
                    types: ['xlsx']
                 }"
                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isMerge:true}"
                :data="tableDataMain"
                :columns="tableColumn"
                :custom-config="{storage:true}"
                @keydown="$_onGridKeydown"
                :header-cell-class-name="cellClassNameEvent"
                :footer-cell-class-name="cellClassNameEvent"
                :cell-class-name="cellClassNameEvent"
                @cell-dblclick="$_cellClickEventKD"
                @cell-selected="cellClickEvent"
                :mouse-config="{selected: true}"
                :footer-method="footerMethod"
                @edit-closed="$refs.baseGird.updateFooter()"
                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                :keyboard-config="{delMethod:$_singleCountEventEvery,enterToTab:false,isArrow: true, isDel: true, isEnter: false, isTab: true,isEdit:isEdit || !formData.is_cancel,enabled:!formData.is_cancel}">
            <!-- 对应序号 -->
            <template #serial_number_edit="{row,rowIndex}">
                <el-input @change="$_serialNumberEvent(row,rowIndex),$_singleCountEventAndSumTopSome(row),row.weighing_heavy = ''" :disabled="!isEdit || formData.is_cancel" type="number" v-model="row.serial_number"
                          clearable></el-input>
            </template>
            <!-- 切割长度 -->
            <template #cutting_edit="{row}">
                <vxe-input type="float" :controls="false" @change="$_setCuttingEvent(row),$_singleCountEventAndSumTopSome(row)" :disabled="!isEdit || formData.is_cancel" v-model="row.cutting"
                          clearable></vxe-input>
            </template>
            <!-- 厚度 -->
            <template #wall_thickness_edit="{row}">
                <vxe-input type="float" :controls="false" @change="$_setWallThicknessEvent(row),$_singleCountEventAndSumTopSome(row)" :disabled="!isEdit || formData.is_cancel" v-model="row.wall_thickness"
                                 clearable></vxe-input>
            </template>
            <!--操作-->
            <template #operate="{row,rowIndex}">
                <el-popover
                        placement="left-start"
                        v-model="row.operateVisible"
                        width="360">
                    <p>插入行：向上插入一行</p>
                    <p>复制行：向下复制一行</p>
                    <div style="text-align: right; margin: 0">
                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$refs.baseGird.clearData(row)">清空行</el-button>
                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertRowEvent(tableDataMain,$refs.baseGird,row,rowIndex)">插入行</el-button>
                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertCopyRowEvent(tableDataMain,$refs.baseGird,row,rowIndex)">复制行</el-button>
                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$_deleteRowEvent(tableDataMain,$refs.baseGird,row,rowIndex)">删除行</el-button>
                        <el-button size="mini" type="text" @click="row.operateVisible = false">取消</el-button>
                    </div>
                    <vxe-button size="mini" icon="el-icon-plus" slot="reference" circle></vxe-button>
                </el-popover>
            </template>
            <!-- 数量、支数 -->
            <template #single_count_edit="{row}">
                <el-input v-model="row.single_count" type="number"
                          @change="row.weighing_heavy ='',$_singleCountEventAndSumTopSome(row)"
                          :disabled="!isEdit || row.dd_state_name == '完成' || formData.is_cancel"
                           clearable></el-input>
            </template>
            <!-- 过磅重(吨) -->
            <template #weighing_heavy_edit="{row}">
                <el-input @change="$_singleCountEventAndSumTopSome(row)"
                          :disabled="formData.is_cancel || (row.unit == '支' && searchType != '进仓单' && (row.category == '方管' || row.category == '矩管')) || (!isEdit &&row.unit != '支') || (row.kdcc_state_man != ''&&row.kdcc_state_man != null)"
                          type="number" v-model="row.weighing_heavy"
                          clearable></el-input>
            </template>
            <!-- 过磅重(吨) -- 加工模块 底部 -->
            <template #weighing_heavy_machining_bottom_edit="{row}">
                <el-input @change="$_singleCountEventAndSumTopSome(row)" :disabled="formData.is_cancel || !isEdit " type="number" v-model="row.weighing_heavy"
                          clearable></el-input>
            </template>
            <!-- 产品金额 -->
            <template #amount_of_product_edit="{row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="$_amountOfProductTOChangeSinglePrice(row),$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEdit || formData.is_cancel "
                           v-model="row.amount_of_product"
                           clearable>
                </vxe-input>
            </template>
            <!-- 产品金额 - 加工 -->
            <template #amount_of_product_machining_edit="{row}">
                <el-input
                        :disabled="!isEdit || formData.is_cancel || row.automatically == '是' "
                        type="number"
                        v-model="row.amount_of_product"
                          clearable>
                </el-input>
            </template>
            <!-- 成本 - 加工 -->
            <template #naked_into_warehouse_cost_edit="{row}">
                <el-input :disabled="!isEdit || formData.is_cancel || row.automatically == '是' " type="number" v-model="row.naked_into_warehouse_cost"
                          clearable></el-input>
            </template>
            <!-- 产品裸价 - 加工 -->
            <template #naked_price_machining_edit="{row}">
                <el-input :disabled="!isEdit || formData.is_cancel || row.automatically == '是' " type="number" v-model="row.naked_price"
                          clearable></el-input>
            </template>
            <!-- 支数(可文本) -->
            <template #single_count_str_edit="{row}">
                <!-- 改不了后端，前端需要拿来计算过磅重 $_singleCountStrEvent({row}), -->
                <el-input @change="$_singleCountStrEvent(row),$_singleCountEventAndSumTopSome(row)" v-model="row.single_count_str"
                          :disabled="!isEdit || formData.is_cancel" clearable></el-input>
            </template>
            <!-- 支重(可文本) -->
            <template #single_weight_str_edit="{row}">
                <el-input v-model="row.single_weight_str"
                          :disabled="!isEdit || formData.is_cancel" clearable></el-input>
            </template>
            <!-- 销售订单规格ID -->
            <!--<template #dd_stock_id_edit="{row}">
                <el-input v-model="row.dd_stock_id"
                          :disabled="!isEdit || formData.is_cancel" clearable></el-input>
            </template>-->
            <!-- 支数 数量 提示  &&row.unit != '支' -->
            <template #single_count_autocomplete_edit="{row}">
                <vxe-pulldown ref="xDown" transfer>
                    <template #default>
                        <vxe-input v-model="row.single_count"  clearable suffix-icon="fa fa-search" :readonly="!isEdit || formData.is_cancel" :disabled="((!isEdit) || formData.is_cancel) && !$store.getters.getLoginAccount.isAdmin"
                                   @keyup="keyUpDropDownEvent($event,{row})"
                                   @focus="focusDropDownEvent({row})"
                                   @blur="xDownIsShow=false"
                                   @clear="row.batch_number = ''"
                                   @change="$_singleCountEventCleandate(row),row.weighing_heavy ='',$_singleCountEventAndSumTopSome(row)"></vxe-input>
                    </template>
                    <template #dropdown>
                        <div class="my-dropdown4">
                            <vxe-grid
                                    ref="dropDownGrid"
                                    align="left"
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    auto-resize
                                    size="mini"
                                    height="320"
                                    :radio-config="{highlight: true,trigger:'row'}"
                                    :pager-config="dropDownTablePage"
                                    :data="dropDownTableData"
                                    :columns="dropDownTableColumn"
                                    :cell-class-name="cellClassxDownSpecification"
                                    @radio-change="radioChangeEvent"
                                    @cell-dblclick="cellDblClickEvent"
                                    @page-change="pageChangeEvent">
                            </vxe-grid>
                        </div>
                    </template>
                </vxe-pulldown>
            </template>
            <!-- 实际规格 2.0 ,$_searchByTotalSingleCount(row.specification,searchType,searchAll,row) -->
            <template #specification_pulldown_edit="{row,rowIndex}">
                <vxe-pulldown ref="xDownSpecification" transfer>
                    <template #default>
                        <vxe-input v-model="row.specification" clearable suffix-icon="fa fa-search" :disabled="!isEdit || row.dd_state_name == '完成' || row.dd_state_name == '部分完成' || formData.is_cancel"
                                   @keyup="keyUpDropDownEventSpecification($event,rowIndex)"
                                   @focus="focusSpecificationDropDownEvent(row)"
                                   @blur="xDownSpecificationIsShow=false,checkSpecification(row),$_searchWarehouseStockBySpecificationName(row,rowIndex)"
                                   @change="$_searchByTotalSingleCount(row.specification,searchType,searchAll),JcTax(row)"></vxe-input>
                    </template>
                    <template #dropdown>
                        <div class="my-dropdown4">
                            <vxe-grid
                                    ref="dropDownGridSpecification"
                                    align="center"
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    auto-resize
                                    size="mini"
                                    height="250"
                                    :radio-config="{highlight: true,trigger:'row'}"
                                    :data="specificationDropDownTableData"
                                    :columns="specificationDropDownTableColumn"
                                    @radio-change="radioChangeEnterEventSpecification($event)">
                            </vxe-grid>
                            <vxe-button style="width: 100%" status="success" icon="fa fa-plus" @click="$refs.AddSpecification.addEvent()" content="+添加新增"></vxe-button>
                        </div>
                    </template>
                </vxe-pulldown>
            </template>

            <!-- 实际规格 -->
            <template #specification_edit="{row , rowIndex}">
                <el-select ref="selectorSpecification" v-model="row.specification"
                           :allow-create="allowCreate" :disabled="!isEdit || formData.is_cancel"
                           @focus="selectorFocusEvent({row})"
                           @change="$_specificationSelect($event,row,searchType,currentPageName),$_searchWarehouseStockBySpecificationName(row, rowIndex),$_getCutting(row),$_getWallThickness(row)"
                           default-first-option
                           no-data-text="没有搜索结果!"
                           placeholder="可搜索查询"
                           clearable filterable remote
                           :loading="loading"
                           @clear="clearSpecificationEvent(row)"
                           :popper-append-to-body="true"
                           :remote-method="(query) => $_searchByTotalSingleCount(query,searchType,searchAll)">
                    <el-option v-for="item in specificationDropDownTableData" :key="item.id"
                               :label="item.specification_name" :value="item">
                        <span style="margin-right:8px;">{{item.specification_name}}</span>
                        <el-tag v-if="searchType == 'ReplaceWarehouseBill'" style="margin-right:15px;" size="mini" type="success">仓库:{{item.warehouse_name ?
                            item.warehouse_name : '异常'}}
                        </el-tag>
                        <el-tag style="margin-right:15px;" size="mini" type="success">库存:{{item.total_single_count ?
                            item.total_single_count : 0}}
                        </el-tag>
                        <el-tag size="mini" type="success">类别:{{ item.category_name}}</el-tag>
                    </el-option>
                </el-select>
            </template>
            <!-- 理计 theoryWeight -->
            <template #theory_weight_edit="{row}">
                <el-select v-model="row.theory_weight" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option :disabled="(!isEdit || formData.is_cancel) && !$store.getters.getLoginAccount.isAdmin"
                           :filter-method="$_theoryWeightListFilter"
                           clearable filterable>
                    <el-option v-for="item in $store.getters.getTheoryWeightList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 足厚 -->
            <template #full_thick_edit="{ row}">
                <el-select v-model="row.full_thick" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option :disabled="!isEdit || formData.is_cancel"
                           :filter-method="$_fullThickListFilter"
                           clearable filterable>
                    <el-option v-for="item in $store.getters.getFullThickList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 类别 -->
            <template #category_edit="{ row}">
                <el-select v-model="row.category" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option @clear="$_searchCategoryList"
                           @change="row.weighing_heavy ='',$_singleCountEventAndSumTopSome(row)"
                           @focus="$_searchCategoryList" :disabled="!isEdit || row.dd_state_name == '完成' || formData.is_cancel"
                           :filter-method="$_categoryListFilter" clearable filterable>
                    <el-option v-for="item in $store.getters.getCategoryList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 单位 -->
            <template #unit_edit="{ row}">
                <el-select v-model="row.unit" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option :disabled="!isEdit || formData.is_cancel"
                           @change="$_singleCountEventAndSumTopSome(row)"
                           @clear="$_singleCountEventAndSumTopSome(row)"
                           @focus="$_unitListFilter"
                           filterable :filter-method="$_unitListFilter" clearable>
                    <el-option v-for="item in $store.getters.getUnitList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 订货 -->
            <template #is_order_edit="{ row}">
                <el-select v-model="row.is_order" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option :disabled="!isEdit || formData.is_cancel"
                           @focus="$_isOrderListFilter"
                           filterable :filter-method="$_isOrderListFilter">
                    <el-option v-for="item in $store.getters.getIsOrderList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 是否自动生成存货成本核算金额 -->
            <template #automatically_edit="{ row}">
                <el-select v-model="row.automatically" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option :disabled="!isEdit || formData.is_cancel"
                           @focus="$_isOrderListFilter"
                           @change="$_automaticallyEvent(row)"
                           filterable :filter-method="$_isOrderListFilter">
                    <el-option v-for="item in $store.getters.getIsOrderList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 调入仓库名称 -->
            <template #warehouse_in_edit="{ row}">
                <el-select v-model="row.warehouse" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                           default-first-option :disabled="!isEdit || formData.is_cancel"
                           @change="$_selectWarehouseIn({row})"
                           @clear="$_searcWarehouseList,$_selectWarehouseIn({row})"
                           @focus="$_searcWarehouseList"
                           :filter-method="$_warehouseListFilter" clearable filterable>
                    <el-option v-for="item in $store.getters.getWarehouseList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 调出仓库名称 -->
            <template #warehouse_out_edit="{ row}">
                <el-select v-model="row.warehouse_out" no-data-text="没有搜索结果!"
                           placeholder="可搜索查询" default-first-option :disabled="(row.kdcc_state_man != ''&&row.kdcc_state_man != null) || row.dd_state_name == '完成' || formData.is_cancel"
                           @change="$_selectWarehouseOut({row})"
                           @clear="$_searcWarehouseList,$_selectWarehouseOut({row})"
                           @focus="$_searcWarehouseList" :filter-method="$_warehouseListFilter"
                           clearable filterable>
                    <el-option v-for="item in $store.getters.getWarehouseList" :key="item.id" :label="item.name"
                               :value="item.name">
                        <span style="margin:15px;">{{ item.name}}</span>
                    </el-option>
                </el-select>
            </template>
            <!-- 订单日期 !isEdit || formData.is_cancel  -->
            <template #stock_bill_date_dd_edit="{ row}">
                <el-date-picker v-model="row.stock_bill_date_dd" placeholder="日期选择"
                                :disabled="(row != null && row.single_count > 0 && !$store.getters.getLoginAccount.isAdmin)" type="datetime"></el-date-picker>
            </template>
            <!-- 产品备注 -->
            <template #remark_stock_edit="{ row}">
                <vxe-input v-model="row.remark_stock"
                           :disabled="!isEdit || formData.is_cancel"></vxe-input>
            </template>
            <!-- 本次报价 -->
            <template #quotation_price_edit="{ row}">
                <el-input @change="quotationPriceEvent(row)"
                          v-model="row.price" :disabled="!isEdit || formData.is_cancel"
                          clearable></el-input>
            </template>
            <!-- 单价 -->
            <template #price_edit="{ row}">
                <el-input @change="row.single_price_practical = row.price,$_singleCountEventAndSumTopSome(row)" type="number"
                          v-model="row.price" :disabled="!isEdit || formData.is_cancel"
                          clearable></el-input>
            </template>
            <!-- 实收单价 -->
            <template #single_price_practical_edit="{ row}">
                <el-input
                        @change="$_singleCountEventAndSumTopSome(row)"
                        type="number" :disabled="!isEdit || formData.is_cancel"
                        v-model="row.single_price_practical" clearable></el-input>
            </template>
            <!-- 实收金额 -->
            <template #practical_price_edit="{ row}">
                <el-input @change="$_practicalPriceTOChangeSinglePricePractical(row),$_singleCountEventAndSumTopSome(row)" :disabled="!isEdit || formData.is_cancel" type="number" v-model="row.practical_price"
                          clearable></el-input>
            </template>
            <!-- 实厚 -->
            <template #actual_thickness_edit="{ row}">
                <el-input v-model="row.actual_thickness" :disabled="(!isEdit || formData.is_cancel) && !$store.getters.getLoginAccount.isAdmin" clearable></el-input>
            </template>
            <!-- 打磨驳口 -->
            <template #polish_roll_edit="{ row}">
                <el-autocomplete
                        v-model="row.polish_roll"
                        :disabled="!isEdit || formData.is_cancel"
                        :fetch-suggestions="$_polishRollQuerySearch"
                        clearable>
                </el-autocomplete>
            </template>
            <!-- 每支单价 -->
            <template #single_price_edit="{ row}">
                <el-input @change="$_singleCountEventAndSumTopSome(row)" :disabled="!isEdit || formData.is_cancel" type="number" v-model="row.single_price" clearable></el-input>
            </template>
            <!--支重 不建议使用，建议统一和销售开单一样的计算逻辑-->
            <template #single_weight_edit="{ row}">
                <el-input type="number" size="mini" :disabled="!isEdit || formData.is_cancel" v-model="row.single_weight" clearable></el-input>
            </template>
            <template #tax_tate_stock_edit="{ row}">
                <el-input type="number"
                          @change="$_checkTaxTateStockRow(),$_taxTateWarehouseBillEvent(),$_singleCountEventEvery(),isDataChanage = true"
                          :disabled="!isEdit || formData.is_cancel" v-model="row.tax_tate_stock" clearable></el-input>
            </template>
            <!-- 磅费 -->
            <template #pounds_fees_edit="{ row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.pounds_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.pounds_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 风割费 -->
            <template #wind_cut_fees_edit="{ row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.wind_cut_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.wind_cut_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 装车费 -->
            <template #loading_car_fees_edit="{ row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.loading_car_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.loading_car_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 中转费 -->
            <template #transfer_fees_edit="{ row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.transfer_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.transfer_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 运费 -->
            <template #freight_fees_edit="{ row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.freight_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.freight_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 手续费 -->
            <template #procedure_fees_edit="{ row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.procedure_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.procedure_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 外省运费 -->
            <template #other_freight_fees_edit="{row}">
                <vxe-input type="float"
                           :controls="false"
                           @change="formData.other_freight_fees_diy = true,$_singleCountEventAndSumTopSome(row)"
                           :disabled="!isEditFees || !isEdit || formData.is_cancel"
                           v-model="row.other_freight_fees"
                           clearable>
                </vxe-input>
            </template>
            <!-- 税额 -->
            <template #tax_edit="{ row}">
                <el-input type="number" v-model="row.tax" :disabled="!isEdit || formData.is_cancel" clearable></el-input>
            </template>
        </vxe-grid>
        <AddSpecification ref="AddSpecification" :isCustomer="false"></AddSpecification>
        <el-dialog width="420px"  title="释放库存" :visible.sync="releaseOrLockForm.showIsOkCount" :close-on-click-modal="false">
            <el-form :rules="releaseOrLockRules" :model="releaseOrLockForm" label-width="100px" @submit.native.prevent="releaseOrLock" >
                <a>{{releaseOrLockForm.message}}</a>
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="释放完成支数" label-width="120px" prop="isOkCount">
                            <el-input-number :controls="false" v-model="releaseOrLockForm.ddIsOkCount" size="mini" style="width: 250px"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="releaseOrLockForm.showIsOkCount = false">取 消</el-button>
                <el-button type="primary" @click="releaseOrLock">确 定</el-button>
            </div>
        </el-dialog>
        <vxe-modal v-model="DDStockIdIsShow" :lock-view="false" :mask="false" resize
                   margin-size="-500" width="1300" title="导入销售订单">
            <template #default>
                <el-row>
                    <el-col :sm="12" :md="5">
                        <el-date-picker
                                style=" margin-bottom: 8px;width: 220px"
                                v-model="pickerDate"
                                type="daterange"
                                size="mini"
                                :clearable="false"
                                :picker-options="pickerOptions"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="left">
                        </el-date-picker>
                    </el-col>
                    <el-col :sm="12" :md="17">
                        <el-button type="primary" @click="searchDDStockList" round
                                   size="mini">搜索查询
                        </el-button>
                        <el-button type="primary" @click="rowMain.dd_stock_id = '',rowMain.stock_bill_date_dd = '',DDStockIdIsShow = false,filterData = {},filterData.bill_id_type = 'JC',sortData.bill_date = 'desc'" round
                                   size="mini">清空行内单号
                        </el-button>
                        <el-button type="primary" @click="insertDDStockList" round
                                   size="mini">导入
                        </el-button>
                        <a style="margin-left: 15px">1、导入按钮：将会把勾选行导入到空白行</a>
                        <a style="margin-left: 15px">2、双击行数据将改变“销售订单规格ID”</a>
                    </el-col>
                </el-row>
                <vxe-grid
                        border
                        ref="DDStockIdGrid"
                        show-overflow
                        resizable
                        height="450"
                        size="mini"
                        highlight-current-row
                        @cell-dblclick="cellClickEventDDStockId"
                        :checkbox-config="{labelField:'id',range:true}"
                        :data="DDStockIdTableDataModel"
                        :columns="DDStockIdTableColumnModel"
                        :mouse-config="{selected: true}"
                        :pager-config="tableDataDDStockModelPage">
                </vxe-grid>
            </template>
        </vxe-modal>
    </div>

</template>

<script>
    import AddSpecification from "../components/AddSpecification";
    import {filterRender} from "@/utils/gird-filter";
    //let debounceTime//防抖 节流
    export default {
        components: {AddSpecification},
        name: "MnGrid",
        props: {
            'id':{
                type: String,
                default: 'noId'
           },
            //tab 产品报价单
            'selectTab':{
                type: String,
                default: ''
           },
            'height': {
                type: String,
                default: '500'
           },
            'tableColumn': {
                type: Array,
                default: () => []
           },
            'tableDataMain': {
                type: Array,
                default: () => []
           },
            'tableDataTop': {
                type: Array,
                default: () => []
           },
            'tableDataFooter': {
                type: Array,
                default: () => []
            },
            'titleList': {
                type: Array,
                default: () => []
           },
            'formData': {
                type: Object,
                default: () => ({})
           },
            'allowCreate': {
                type: Boolean,
                default: false
           },
            //搜索类型 ReplaceWarehouseBill
            'searchType': {
                type: String,
                default: ''
           },
            'searchAll': {
                type: Boolean,
                default: true
           },
            'currentPageName': {
                type: String,
                default: ''
           },
            'isEdit': {
                type: Boolean,
                default: true
           },
            'searchWarehouseBill':{
                type: Function,
                default: null
           },
            'saveOrUpdate':{
                type: Function,
                default: null
           }

       },
        data() {
            return {
                debounceTime: null, // 把 debounceTime 放到 data 中，确保每个组件实例独立
              debounceTimer: null,
              cancelToken: null, // 用于取消请求
                tempMnGridRow:null,
                rowMain: {},
                rowIndexMain: 0,
                tableDataDDStockModelPage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [500, 1000, 2000, 5000]
                },
                DDStockIdTableDataModel:[],
                DDStockIdTableColumnModel: [
                    {
                        field:'id',type: 'checkbox', title: '销售开单规格ID', width: 120,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'customer',  width: 220, title: '客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'only_bill_id', width: 165, title: '订单单号',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'only_bill_date', width: 95, title: '日期',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'specification', width: 160, title: '实际规格',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'category', width: 55, title: '类别',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'warehouse_out', width: 55, title: '仓库',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'single_count', width: 60, title: '订单支数',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'dd_is_ok_count', width: 70, title: '已完成支数',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'dd_is_ok_count_hand', width: 80, title: '手动已完成支数',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'dd_is_ok_count_lock', width: 70, title: '未完成支数',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'unit', width: 55, title: '单位',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'price', width: 60, title: '单价',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'single_price_practical', width: 60, title: '实收单价',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'amount_of_product', width: 60, title: '产品金额',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'practical_price', width: 60, title: '实收金额',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                    {
                        field:'theoretical_weight', width: 60, title: '理论重量',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/transferSlip/searchHeardByDDStockList', this.pickerDate)
                        }
                    },
                ],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date, date]);
                        }
                    }, {
                        text: '最近周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date, new Date()]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                DDStockIdIsShow:false,
                releaseOrLockRules: {
                    ddIsOkCount : [
                        {required: true, message: "必填", trigger: "blur"},
                    ],
                },
                releaseOrLockForm:{
                    message:'',
                    id:0,
                    ddIsOkCount:0,//用于提交释放的数量
                    showIsOkCount:false,
                },
                //selecting:false,//用于异步查限制
                tempSearchSpecification:'',
                tempSearchCategory:'',
                isEditFees:true,
                goBackRow:false,//左键盘向上换行
                xDownIsShow: false,//下拉框 回车选中
                xDownSpecificationIsShow: false,//实际规格下拉框 回车选中
                //unitList: [],//单位(筛选)
                isOrderList: [],//订货(筛选)
                dropDownTableColumn: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'bill_type',title: '单据类型', width: 80},
                    {field:'batch_single_count',slots: {
                            // 使用 JSX 渲染
                            default: ({row}) => {
                                if (row.batch_single_count > 0) {
                                    return row.batch_single_count
                                }else{
                                    return [
                                        <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content">
                                        查询条件：批号数 > 0 或者 批号等于该行原本批号
                                            <br/> 负数：假设这行出了100支，仓库是变成了负数，当“更新单据”查询的是该批号所以会显示负数
                                        </div>
                                        <i class="el-icon-info" style=" width: 1em; height: 1em; "> {row.batch_single_count}</i>
                                        </el-tooltip>
                                    ]
                                }
                            }
                        },
                        titleHelp: {message: '查询条件：批号数 > 0 或者 批号等于该行原本批号'},  title: '仓库批次可用数', width: 55},
                    {field:'is_order', title: '是否订货', width: 50},
                    {field:'batch_number', title: '批号', width: 140},
                    {field:'supplier', title: '供应商', width: 130},
                    {field:'category', title: '类别', width: 60},
                    {field:'full_thick', title: '足厚', width: 50},
                    {field:'single_weight', title: '支重', width: 80},
                    {field:'warehouse', title: '仓库', width: 50},
                    {field:'remark_stock', title: '进货单备注', width: 130},
                    {
                        field:'bill_date', title: '日期', width: 80,
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yy-MM-dd')
                       }
                   },
                    {field:'tax_tate_stock', title: '产品税率', width: 80, formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue + '%'
                            }
                        }
                    },
                    {field:'specification', title: '规格', width: 130},
                ],
                specificationDropDownTableColumn: [
                    {type: 'radio', title: '选择', width: 50},
                    {field:'category_name', title: '类型', width: 100},
                    {field:'total_single_count', title: '支数', width: 100},
                    {field:'warehouse_name',visible: this.searchType == 'ReplaceWarehouseBill', title: '仓库', width: 100},//searchType == 'ReplaceWarehouseBill'
                    {field:'specification_name', title: '规格', width: 150},
                ],
                headerCellClassName:'',
                cellClassName:'',
                loading: false,
                dropDownTableData: [],
                dropDownTablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [50, 100, 200, 500]
               },
                specificationDropDownTableData: [],
                specificationDropDownTablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 5,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },
                filterData: {
                    bill_id_type: 'JC',
                    only_bill_id: '',
                    specification: '',
                    batch_number: '',
                    category: '',
                    customer: ''
                },
                sortData: {"bill_date": "desc"},
                pickerDate: [new Date('2022-01-01'), new Date(new Date().getTime() + 3600 * 1000 * 24 * 730)],
           }
       },
        mounted: function () {
           // this.inputCustName()
            /*this.$refs.selectorSpecification.$el.getElementsByTagName('input')[0].oninput = (e)=>{
                console.log('mounted e',e)
                //this.$refs.elSelec.remoteMethod(e.target.value)
           }*/
       },
        created() {
            /*this.$_searchUnitList('');
            this.$_searcWarehouseList('');*/
       },
        watch: {
            /*tableDataMain: {
                //deep: true,
                handler (newVal) {
                    console.log('watch');
                    // 为了不请求多次接口可以加上函数节流，具体方法可以查看 点击查看第四点
                    newVal.forEach((item)=>{
                        console.log(item);
                   })
                    //this.$_searchByTotalSingleCount(newVal,"",true)
               }
           }*/
         /* selectTab() {
            // 清空Vuex中的规格列表
            this.$store.commit('CLEAR_SPECIFICATION_LIST')
            // 重置防抖定时器
            if (this.debounceTime) {
              clearTimeout(this.debounceTime)
              this.debounceTime = null
            }
          }*/
       },

      methods: {
            quotationPriceEvent(row){
                try{
                    row.price = eval(row.price)
                    //console.log(row.price)
                }catch(exception) {
                    console.warn('公式不对',exception);
                }
                row.single_price_practical = row.price
                this.$_singleCountEventAndSumTopSome(row)
            },
            cellClickEventDDStockId({row}){
                /*console.log(row)
                console.log(this.rowMain)*/
                this.rowMain.dd_stock_id = row.id
                if (this.rowMain.warehouse_out_id != row.warehouse_out_id){
                    this.rowMain.batch_number = ''
                }
                this.rowMain.unit = row.unit
                if(this.isBlankVue(this.rowMain.price)){
                    this.rowMain.price = row.price
                }
                if(this.isBlankVue(this.rowMain.amount_of_product)){
                    this.rowMain.amount_of_product = row.amount_of_product
                }
                if(this.isBlankVue(this.rowMain.practical_price)){
                    this.rowMain.practical_price = row.practical_price
                }
                if(this.isBlankVue(this.rowMain.single_price_practical)){
                    this.rowMain.single_price_practical = row.single_price_practical
                }
                if(this.isBlankVue(this.rowMain.warehouse_out)){
                    this.rowMain.warehouse_out = row.warehouse_out
                }
                if(this.isBlankVue(this.rowMain.warehouse_out_id)){
                    this.rowMain.warehouse_out_id = row.warehouse_out_id
                }
                this.rowMain.specification = row.specification
                this.rowMain.stock_bill_date_dd = row.only_bill_date
                this.rowMain.category = row.category
                if(this.isBlankVue(this.rowMain.single_count)){
                    this.rowMain.single_count = row.dd_is_ok_count_lock
                    this.rowMain.single_count_str = row.dd_is_ok_count_lock
                }
                this.rowMain.theoretical_weight = row.theoretical_weight
                this.DDStockIdIsShow = false
                this.filterData = {
                    bill_id_type : 'JC',
                }
                this.sortData = {
                    bill_date : 'desc'
                }
            },
            async insertDDStockList(){
                const tableCheck = this.$refs.DDStockIdGrid.getCheckboxRecords();
                for (let t in tableCheck) {
                    const record = Object.assign({}, this.rowMain)
                    await Object.keys(record).forEach(key => {
                        if(key != 'cash_type' && key != 'operateVisible'){
                            (record[key] = '')
                        }
                    });
                    record['dd_stock_id'] = tableCheck[t].id
                    record['warehouse_out'] = tableCheck[t].warehouse_out
                    record['warehouse_out_id'] = tableCheck[t].warehouse_out_id
                    record['specification'] = tableCheck[t].specification
                    record['category'] = tableCheck[t].category
                    record['single_count'] = tableCheck[t].dd_is_ok_count_lock
                    record['single_count_str'] = tableCheck[t].dd_is_ok_count_lock
                    record['unit'] = tableCheck[t].unit
                    record['price'] = tableCheck[t].price
                    record['stock_bill_date_dd'] = tableCheck[t].only_bill_date
                    record['single_price_practical'] = tableCheck[t].single_price_practical
                    record['amount_of_product'] = tableCheck[t].amount_of_product
                    record['practical_price'] = tableCheck[t].practical_price
                    record['theoretical_weight'] = tableCheck[t].theoretical_weight
                    await this.$refs.baseGird.insertAt(record, this.rowMain)
                    await this.tableDataMain.splice(this.rowIndexMain,0,record);
                }
                this.DDStockIdIsShow = false
                this.filterData = {
                    bill_id_type : 'JC',
                }
                this.sortData = {
                    bill_date : 'desc'
                }
            },
            searchDDStockList(){
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlip/searchDDStockList',
                    data: {
                        currentPage: this.tableDataDDStockModelPage.currentPage,
                        pageSize: this.tableDataDDStockModelPage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.DDStockIdTableDataModel = response.data.page.list
                        this.tableDataDDStockModelPage.pageSize = response.data.page.pageSize
                        this.tableDataDDStockModelPage.total = response.data.page.totalRow
                        this.tableDataDDStockModelPage.currentPage = response.data.page.pageNumber
                        //console.log(response)
                    } else {
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            clearSpecificationEvent(row){
                if (row != null){
                    row.warehouse_out = '';
                    row.warehouse_out_id = '';
                    row.category = '';
                    row.unit = '';
               }
           },
            //setTimeout 用于重新修改select中的值
            //回显
            selectorFocusEvent({row}) {
                //console.log(row)
                if (!this.isBlankVue(row.specification) && !this.isBlankVue(this.$refs.selectorSpecification) && !this.isBlankVue(this.$refs.selectorSpecification.selectedLabel) ) {
                    setTimeout(() => {
                        this.$refs.selectorSpecification.selectedLabel = row.specification;
                        //row.specification=this.$refs.selectorSpecification.selectedLabel;
                   }, 10)
               }
           },
            //{"filterData":{"bill_id":"","specification":"100*100*10*6米","bill_id_type":"JC"},"pageSize":100,"currentPage":1,"sortData":{"creat_date_warehouse_bill":"desc"},"pickerDate":["2021-09-07T13:54:19.241Z","2021-12-06T13:54:19.241Z"]}
            //row.specification,row.category
            async searchJCWarehouse(row) {
                this.dropDownTableData = []
                this.sortData={"bill_date": "desc"}
                if (!this.isBlankVue(row.specification)) {
                    this.filterData.specification = row.specification;
                    this.filterData.category = row.category;
                    await this.$axios({
                        method: 'POST',
                        url: '/admin/allWarehouse/search',
                        data: {
                            currentPage: this.dropDownTablePage.currentPage,
                            pageSize: this.dropDownTablePage.pageSize,
                            sortData: this.sortData,
                            filterData: this.filterData,
                            pickerDate: this.pickerDate,
                            searchType: this.searchType,
                            singleCount:row.single_count,//负数的话，显示所有规格批号
                            warehouse_out_id:row.warehouse_out_id,//仓库，新仓，可以显示负数的批号
                            searchBatchNumber: true,
                            searchBatchNumberRow: row.batch_number,
                            isAccurate:true
                        }
                    }).then((response) => {          //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200) {
                            this.dropDownTableData = response.data.page.list
                            this.dropDownTablePage.pageSize = response.data.page.pageSize
                            this.dropDownTablePage.total = response.data.page.totalRow
                            this.dropDownTablePage.currentPage = response.data.page.pageNumber
                        }
                    }).catch((error) => {
                        console.log(error)
                    });
                }

                /*if (!this.isBlankVue(specification)) {
                    this.filterData.specification = specification;
                    if (!this.isBlankVue(category)) {
                        this.filterData.category = category;
                   }else{
                        delete this.filterData.category;//删除category元素 删除对象属性
                   }

               } else {
                    this.dropDownTableData = []
               }*/
           },


            /**
             * 表格覆盖模式
             * @param row
             * @param column
             */
            editMethod({row, column}) {
                const $table = this.$refs.baseGird
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            cellClassxDownSpecification({row,column}){
                if (column.property == 'batch_single_count' && row != null) {
                    if (row.batch_single_count <= 0) {
                        return 'col-yellow'
                    }
                }
            },
            cellClassNameEvent({row,column}){
                if(column.property == 'stock_bill_date_dd' && row != null && row.single_count > 0 && !this.$store.getters.getLoginAccount.isAdmin){
                        return 'unEditor'
                }else if (column.property == 'dd_state_name' && row != null) {
                    if (row.dd_state_name == '完成') {
                        return 'col-success'
                    }else if(row.dd_state_name == '部分完成'){
                        return 'col-yellow'
                    }else{
                        return 'col-fail'
                    }
               }else if (column.property == 'kdcc_state_man') {
                    if (row != null && !this.isBlankVue(row.kdcc_state_man)) {
                        //this.$parent.searchSalesOrdersSingle();
                        return 'col-success'
                    }else{
                        return 'col-fail'
                    }
                }
                else if (column.property === this.cellClassName) {
                    return 'selectedColor'
               }
                else if (column.property === 'dd_stock_id') {
                    return 'selectedColor'
                }else if(this.searchType == 'machining' && (column.property === 'amount_of_product' || column.property === 'naked_price') && row != null && !this.isBlankVue(row.automatically) && row.automatically == '是'){
                    return 'unEditor'
                }else if(column.property == 'weighing_heavy' && row != null &&  row.unit == '支' && this.searchType != '进仓单 '&& this.searchType != 'machining2'){
                    return 'unEditor'
                }
           },
            /*cellClickEvent:debounce(function ({row,column,rowIndex}) {
                console.log('防抖e',row)
                if (this.formData.bill_id_type == 'BJ'){
                    this.searchSpecificationRowTableData(this.selectTab,row);
               }
                this.cellClassName = column.property;
                if (column.property == 'remark_stock'){
                    const $table = this.$refs.baseGird
                    //console.log('$table',$table)
                    // 重写默认的覆盖式，改为追加式
                    $table.setActiveCell(row, column)
               }
                this.$_searchWarehouseStockBySpecificationName(row,rowIndex);
           }, 500, true),*/
            cellClickEvent({row,column,rowIndex}) {
                //console.log(row.single_count,row.single_count_temp)
                row.single_count_temp = row.single_count
                this.tempMnGridRow = row;
                //console.log('cellClickEvent -> tempMnGridRow',this.tempMnGridRow)
                let that = this
                //防抖 节流
                if (this.debounceTime) {
                    clearTimeout(this.debounceTime)
                }
              this.debounceTime = setTimeout(function () {
                    if (that.formData.bill_id_type == 'BJ'){
                        that.searchSpecificationRowTableData(that.selectTab,row,false);
                    }
                    this.cellClassName = column.property;
                    if (column.property == 'remark_stock'){
                        const $table = that.$refs.baseGird
                        //console.log('$table',$table)
                        // 重写默认的覆盖式，改为追加式
                        $table.setActiveCell(row, column)
                   }
                    that.$_searchWarehouseStockBySpecificationName(row,rowIndex);
                    this.debounceTime = undefined;
               }, 300)
           },
            handleClickTab(selectTabVal){
                //console.log('this.tempMnGridRow',this.tempMnGridRow)
                //console.log('进入searchSpecificationRowTableData tab方法1')
                this.searchSpecificationRowTableData(selectTabVal,this.tempMnGridRow,true);
            },
            searchSpecificationRowTableData(val,row,changeTab){
                //console.log('searchSpecificationRowTableData方法')
                //console.log('selectTab',val);
                //setTimeout(() => {
                    //console.log("执行",val)
                    //let row = this.$refs.baseGird.getSelectedCell();
                    //console.log(row)
                // console.log(row.specification)
                // console.log(row.category)
                    if (row != null && !this.isBlankVue(row.specification) && (this.tempSearchSpecification != row.specification || this.tempSearchCategory != row.category || this.selectTab != val)) {
                        this.tempSearchSpecification = row.specification //记录起来查询的历史数据，同一行数据不需要重复查询
                        this.tempSearchCategory = row.category //记录起来查询的历史数据，同一行数据不需要重复查询
                        if (val == 'tab1'){
                            this.$emit('childByTableDataJC', [])
                            this.searchSpecificationRowTableDataJC(row.specification,row.category);
                        }else if(val == 'tab2'){
                            this.$emit('childByTableDataBJ', [])
                            this.searchSpecificationRowTableDataBJ(row.specification,row.category);
                        }else if(val == 'tab3'){
                            this.$emit('childByTableDataHC', [])
                            this.searchSpecificationRowTableDataHC(row.specification,row.category);
                        }
                        if (!changeTab){
                            this.$emit('childByTableDataKD', [])
                            this.searchSpecificationRowTableDataKD(row.specification,row.category);
                        }
                   }
                //}, 300)

           },
            //查询实际规格 各仓库的库存，查询最近进仓情况、销售情况、历史报价、调仓情况
            //{"filterData":{"bill_id":"","specification":"140*60*5*6米","bill_id_type":"JC"},"pageSize":100,"currentPage":1,"sortData":{"bill_date":"desc"},"pickerDate":["2021-09-21T14:42:02.974Z","2021-10-21T14:42:02.974Z"]}
            searchSpecificationRowTableDataJC(specification,category) {
                this.$emit('childByTableDataJC', [])
                if(!this.isBlankVue(specification)){
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 730);
                    this.$axios({
                        method: 'POST',
                        url: '/admin/productQuotation/searchSpecificationRow',
                        data: {
                            currentPage: 1,
                            pageSize: 50,
                            isAccurate: true,
                            searchType: this.searchType,
                            sortData: {"count_zero_rn": "desc","bill_stock_date": "desc"},
                            filterData: {"specification": specification,"category": category, "stock_type": "JC"},
                            ////"filterData":{"specification":"100*100*2.5*6米","bill_id_type":"KD"}
                            pickerDate: [start, end],
                       }
                   }).then((response) => {          //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200) {
                            //判断list不是空，取第一个值来看看是不是相等，相等才覆盖进去。否则不进行任何变动
                            if (this.tempSearchSpecification == specification){
                                this.$emit('childByTableDataJC', response.data.page.list)
                            }
                       }
                   }).catch((error) => {
                        console.log(error)
                   });
               }
           },
            searchSpecificationRowTableDataKD(specification,category) {
                this.$emit('childByTableDataKD', [])
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 360);
                end.setTime(end.getTime() + 3600 * 1000 * 24 * 360);
                //console.log('start',start)
                this.$axios({
                    method: 'POST',
                    url: '/admin/productQuotation/searchSpecificationRow',
                    data: {
                        currentPage: 1,
                        pageSize: 50,
                        sortData: {"bill_stock_date": "desc"},
                        isAccurate: true,
                        searchType: this.searchType,
                        filterData: {"specification": specification,"category": category, "stock_type": "KD"},
                        ////"filterData":{"specification":"100*100*2.5*6米","bill_id_type":"KD"}
                        pickerDate: [start, end],
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log('response.data.page',response.data.page)       //请求成功返回的数据
                    if (response.status == 200) {
                        //console.log(this.tableDataKD);
                       // this.tableDataKD = response.data.page.list
                        //console.log(this.tableDataKD);
                        // 第二个参数this.childValue是需要传的值
                        if (this.tempSearchSpecification == specification){
                            this.$emit('childByTableDataKD', response.data.page.list)
                        }
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            async searchSpecificationRowTableDataBJ(specification,category) {
                this.$emit('childByTableDataBJ', [])
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 360);
                await this.$axios({
                    method: 'POST',
                    url: '/admin/productQuotation/searchSpecificationRow',
                    data: {
                        currentPage: 1,
                        pageSize: 50,
                        sortData: {"bill_stock_date": "desc"},
                        isAccurate: true,
                        searchType: this.searchType,
                        filterData: {"specification": specification, "category": category,"stock_type": "BJ"},
                        ////"filterData":{"specification":"100*100*2.5*6米","bill_id_type":"KD"}
                        pickerDate: [start, end],
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        if (this.tempSearchSpecification == specification){
                            this.$emit('childByTableDataBJ', response.data.page.list)
                        }
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            async searchSpecificationRowTableDataHC(specification,category) {
                this.$emit('childByTableDataHC', [])
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 360);
                await this.$axios({
                    method: 'POST',
                    url: '/admin/productQuotation/searchSpecificationRow',
                    data: {
                        currentPage: 1,
                        pageSize: 50,
                        sortData: {"bill_stock_date": "desc"},
                        isAccurate: true,
                        searchType: this.searchType,
                        filterData: {"specification": specification,"category": category, "stock_type": "HC"},
                        ////"filterData":{"specification":"100*100*2.5*6米","bill_id_type":"KD"}
                        pickerDate: [start, end],
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        //子传父，子修改父
                        if (this.tempSearchSpecification == specification){
                            this.$emit('childByTableDataHC', response.data.page.list)
                        }
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                if (field === 'the_theory_of_count' || field === 'amount_of_product' || field === 'naked_price'|| field.endsWith('Fees')){
                    count = this.$XEUtils.commafy(count ? count : '', { digits: 2}) + '';
               }
                //return this.$XEUtils.commafy(count ? count : '', { digits: 2}) + '';
                return count;
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'kick_back' || column.property === 'practical_price'
                            || column.property === 'single_count' || column.property === 'weighing_heavy'
                            || column.property === 'amount_of_product' || column.property === 'practical_total_price'
                            || column.property === 'payend' || column.property === 'balance'
                            || column.property === 'other_expenses_price' || column.property === 'naked_price'
                            || column.property === 'the_theory_of_count'
                            || column.property === 'taxes_fees'|| column.property === 'pounds_fees' || column.property === 'wind_cut_fees'|| column.property === 'loading_car_fees'
                            || column.property === 'transfer_fees'|| column.property === 'freight_fees'|| column.property === 'other_freight_fees'
                            || column.property === 'procedure_fees'
                            || column.property === 'naked_other_freight_fees_tax_money' || column.property === 'other_freight_fees_tax_money' || column.property === 'naked_into_warehouse_cost'
                            || column.property === 'actual_sales_amount'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            cellDblClickEvent() {
                //console.log(row.single_weight);
                this.$refs.xDown.hidePanel()
           },
            specificationCellDblClickEvent() {
                this.$refs.xDownSpecification.hidePanel()
           },
            pageChangeEvent({currentPage, pageSize}) {
                this.dropDownTablePage.currentPage = currentPage
                this.dropDownTablePage.pageSize = pageSize
                const activeRow = this.$refs.baseGird.getActiveRecord().row;
                this.searchJCWarehouse(activeRow);
           },
            focusDropDownEventGrid(row){
                this.focusDropDownEvent({row});
           },
            focusDropDownEvent({row}) {
                this.filterData.bill_id_type = 'JC'
                //console.log(row.single_count_temp)
                this.searchJCWarehouse(row);
                this.$refs.xDown.showPanel();
                this.xDownIsShow=true;
           },
            // eslint-disable-next-line no-unused-vars
            keyUpDropDownEvent(event,{row}) {
                this.filterData.bill_id_type = 'JC'
                /*this.sortData = {
                    bill_id_type : 'JC'
                }*/
                //console.log('row.specification：',row.specification);
                //console.log("触发按键keyUpDropDownEvent", event.$event.code)
                //this.$refs.baseGird.setRadioRow(this.dropDownTableData[1]);
                //console.log(event)
                // this.searchJCWarehouse(row).then(() =>{
                //
                // });

                if (this.xDownIsShow == false){
                    this.xDownIsShow = true;
                    this.$refs['xDown'].showPanel();
                }
                let i = 0;
                if (event.$event.code == 'ArrowUp' || event.$event.code == 'ArrowDown') {
                    if (this.$refs.dropDownGrid.getRadioRecord() != null) {
                        i = this.$refs.dropDownGrid.getRowIndex(this.$refs.dropDownGrid.getRadioRecord());
                        if (event.$event.code == 'ArrowUp') {
                            if (i > 0) {
                                i = i - 1;
                            }
                        } else if (event.$event.code == 'ArrowDown') {
                            i = i + 1;
                        }
                    }
                    //console.log("i: " + i)
                    this.$refs.dropDownGrid.setRadioRow(this.dropDownTableData[i]);
                    this.$refs.dropDownGrid.scrollToRow(this.dropDownTableData[i]);
                }
                //暂时不需要
                else if(event.$event.code == 'Enter' || event.$event.code == 'NumpadEnter'){
                    //如果有内容，则回车默认选中第一个值
                    //console.log('radioChangeEnterEvent回车');
                    this.radioChangeEnterEvent(this.$refs.dropDownGrid.getRadioRecord());
                }
           },
            focusSpecificationDropDownEvent(row) {
                //this.searchJCWarehouse(row.specification);
                this.$refs.xDownSpecification.showPanel();
                this.xDownSpecificationIsShow=true;
                this.$_searchByTotalSingleCount(row.specification,this.searchType,this.searchType != 'ReplaceWarehouseBill',row);
           },
            keyUpDropDownEventSpecification(event) {
                //console.log('row.specification：',row.specification);
                //console.log("触发按键keyUpDropDownEvent", event.$event.code)
                //this.$refs.baseGird.setRadioRow(this.dropDownTableData[1]);
                if (this.xDownSpecificationIsShow == false){
                    this.xDownSpecificationIsShow = true;
                    this.$refs['xDownSpecification'].showPanel();
               }
                let i = 0;
                if (event.$event.code == 'ArrowUp' || event.$event.code == 'ArrowDown') {
                    if (this.$refs.dropDownGridSpecification.getRadioRecord() != null) {
                        i = this.$refs.dropDownGridSpecification.getRowIndex(this.$refs.dropDownGridSpecification.getRadioRecord());
                        if (event.$event.code == 'ArrowUp') {
                            if (i > 0) {
                                i = i - 1;
                           }
                       } else if (event.$event.code == 'ArrowDown') {
                            i = i + 1;
                       }
                   }
                    //console.log("i: " + i)
                    this.$refs.dropDownGridSpecification.setRadioRow(this.specificationDropDownTableData[i]);
                    this.$refs.dropDownGridSpecification.scrollToRow(this.specificationDropDownTableData[i]);
               }
                //暂时不需要
                else if(event.$event.code == 'Enter' || event.$event.code == 'NumpadEnter'){
                    //如果有内容，则回车默认选中第一个值
                    //console.log('radioChangeEnterEvent回车'+ i);
                    let radioRecord = null;
                    if (this.$refs.dropDownGridSpecification.getRadioRecord() == null && this.specificationDropDownTableData != null && this.specificationDropDownTableData.length > 0) {
                      radioRecord = this.specificationDropDownTableData[0];
                    }else{
                      radioRecord = this.$refs.dropDownGridSpecification.getRadioRecord();
                    }

                    let baseGirdRow = this.$refs.baseGird.getActiveRecord().row;
                    //console.log('radioRecord'+ radioRecord);
                    //console.log('baseGirdRow'+ baseGirdRow.specification);
                    //没有选择，则需要去重查整个结果集
                    if(this.isBlankVue(radioRecord)){
                        //console.log("this.isBlankVue(radioRecord)");
                        this.$_searchByTotalSingleCount(baseGirdRow.specification,this.searchType,this.searchType != 'ReplaceWarehouseBill').then(() => {
                            if (!this.isBlankVue(this.specificationDropDownTableData) && this.specificationDropDownTableData.length > 0 ){
                                radioRecord = this.specificationDropDownTableData[0];
                                this.radioEnterEventSpecification(radioRecord);
                           }else{
                                //缩掉下拉框
                                this.xDownSpecificationIsShow = false;
                                //console.log("下拉框");
                                this.$refs.xDownSpecification.hidePanel();
                           }
                       });

                   }else{
                        this.radioEnterEventSpecification(radioRecord);
                   }
               }
           },
            radioChangeEvent({row}) {
                this.radioChangeEnterEvent(row);
           },
            radioChangeEnterEvent(row) {
                //console.log('row.weighingHeavy',row);
                if (row != null){
                    const activeRow = this.$refs.baseGird.getActiveRecord().row;
                    //activeRow.weighing_heavy = this.$XEUtils.divide(this.$XEUtils.multiply(activeRow.single_count , row.single_weight), 1000);
                    //取消仓库跟着批号变动
                    //if (activeRow.warehouse_out_id == null || activeRow.warehouse_out_id == ''){
                        activeRow.warehouse_out = row.warehouse;
                        activeRow.warehouse_out_id = row.warehouse_id;
                   //}
                    //换成单才会读取 对应的足厚、理计
                    if (this.searchType == 'ReplaceWarehouseBill'){
                        activeRow.remark_stock = row.remark_stock;
                        activeRow.theory_weight = row.theory_weight;
                    }
                    activeRow.full_thick = row.full_thick;
                    activeRow.single_weight = row.single_weight;
                    activeRow.batch_number = row.batch_number;
                    //activeRow.tax_tate_stock = row.tax_tate_stock;
                    activeRow.weighing_heavy = this.$XEUtils.round(this.$XEUtils.divide(this.$XEUtils.multiply(activeRow.single_count , activeRow.single_weight), 1000),6);
                    //根据批号查询税率
/*                    console.log('remark_warehouse_bill:' + this.formData.remark_warehouse_bill)
                    console.log('remark_warehouse_bill:' + this.$parent.formData)
                    console.log('remark_warehouse_bill:' + this.$parent.$parent.formData)
                    console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.formData)
                    console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.$parent.formData)
                    console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.$parent.$parent.formData)
                    console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.$parent.$parent.$parent.formData)*/
                    if (this.$parent.$parent.$parent.$parent.$parent.pageType =='销售开单' && !this.isBlankVue(this.$parent.$parent.$parent.$parent.$parent.formData.remark_warehouse_bill)){
                        this.$_batchNumberGetSupperTax(this.$parent.$parent.$parent.$parent.$parent.formData.remark_warehouse_bill,activeRow,row);
                    }
                    this.$_singleCountEventEvery();
               }
                this.$refs['xDown'].hidePanel();
                this.xDownIsShow = false
           },
            radioEnterEventSpecification(rowRadio){
                const activeRecord = this.$refs.baseGird.getActiveRecord();//获取当前激活的行
                //const {columnIndex, rowIndex, row} = activeRecord || this.$refs.baseGird.$grid.getSelectedCell() || {}
                const {columnIndex, rowIndex, row} = activeRecord || {}
                const columns = this.$refs.baseGird.getColumns();//获取当前列
                let nextColumn = columns.find((item, idx) => {
                    //console.log('item',item)
                    //&& item.disabled == false
                    return idx > columnIndex && !!item.editRender && item.className != 'unEditor'
                });
                //console.log('还在回车：radioEnterEventSpecification',nextColumn)
                //console.log('rowRadio: ' + rowRadio)
                //console.log( rowRadio)
                if (rowRadio != null){
                    //const activeRow = this.$refs.baseGird.getActiveRecord().row;
                    //console.log('this.$refs.baseGird.getActiveRecord() row ',activeRow);
                    if (rowRadio.specification_name != null && rowRadio.specification_name != ''){
                        row.specification = rowRadio.specification_name;
                    }
                    //加工单，默认设置是
                    if (this.searchType == 'machining'){
                        row.automatically = '是';
                    }
                    row.category = rowRadio.category_name;
                    this.$refs['xDownSpecification'].hidePanel();
                    this.xDownSpecificationIsShow = false
                    this.$_specificationSelect(rowRadio,row,this.searchType,this.currentPageName);
                    this.$_searchWarehouseStockBySpecificationName(row, rowIndex);
                    this.$_singleCountEventAndSumTopSome(row);
                    this.$_getCutting(row);
                    this.$_getWallThickness(row);
               }
                this.$refs.baseGird.setSelectCell(row, nextColumn);
                this.checkSpecification(row)
           },

            checkSpecification(row){
                setTimeout(() => {
                    if (this.formData.bill_id_type != 'JC'){
                        row.batch_number = ''
                    }
                    if (this.formData.bill_id_type == 'KD' || this.formData.bill_id_type == 'DD' || this.formData.bill_id_type == 'KDTZS'
                        || this.formData.bill_id_type == 'FH' || this.formData.bill_id_type == 'CC' || this.formData.bill_id_type == 'JG'|| this.formData.bill_id_type == 'HC' ){
                        //最后的校验，确定一下仓库有没有这个规格，如果进仓单说明增加规格，可以不需要校验
                        this.$axios({
                            method:'POST',
                            url:'/admin/specification/checkSpecification',
                            params:{
                                specification_name:row.specification,
                                category_name :row.category
                           }
                       }).then((response) =>{          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200){
                                if(response.data.state == "fail"){
                                    row.specification = ''
                               }
                           }
                       }).catch((error) =>{
                            console.log(error)
                            row.specification = ''
                       });
                   }
                    //报价单 自动加米
                    else if(this.formData.bill_id_type == 'BJ'){
                        if (!this.isBlankVue(row.specification) && row.specification.split("*").length == 4
                            && row.specification.indexOf("米") == -1 && row.specification.indexOf("非") == -1
                            && row.specification.indexOf("T") == -1 && row.specification.indexOf("Q") == -1 && row.specification.indexOf(" ") == -1){
                            row.specification = row.specification + '米'
                       }
                   }
                }, 100)
                //console.log('准备校验',row.specification);
           },
            radioChangeEnterEventSpecification({row}) {
                //console.log('row ',row);
                this.radioEnterEventSpecification(row);
           },
            JcTax(row){
                //进仓单税率设置，如果顶部存在税率，直接读取下来行内
                if (this.$parent.$parent.$parent.$parent.$parent.pageType =='进仓单' && !this.isBlankVue(this.$parent.$parent.$parent.$parent.$parent.formData.tax_tate_warehouse_bill)){
                    row.tax_tate_stock = this.$parent.$parent.$parent.$parent.$parent.formData.tax_tate_warehouse_bill;
                }
            },
            /**
             * 获取表格可以更新的记录
             * @returns {number}
             */
            getRecordset() {
                return this.$refs['baseGird'].getRecordset();
           },
            //编辑状态
            setActiveCell(row, field) {
                return this.$refs['baseGird'].setActiveCell(row, field);
           },
            connect(obj) {
                return this.$refs['baseGird'].connect(obj);
           },
            /*cellClick(e) {
                this.$emit('cellClick', e);
           },*/
            importMethod ({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                formBody.append('formData', JSON.stringify(this.formData))
                this.$axios({
                    method:'post',
                    url:'/admin/warehouseEntryBill/importExcel',
                    data: formBody,
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        //console.log('this.$parent',this.$parent);
                        //this.changeTableDataMain(response.data.stocks);
                        //子传父，子修改父
                        this.$emit('childByTableDataMain', response.data.stocks)
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
       }
   }

</script>

<style>
    .el-select-dropdown__wrap {
        max-height: 200px !important;
   }
    .vxe-icon--close{
        height: 1.6em
   }
</style>
